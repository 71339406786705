@import url("https://use.typekit.net/bjv0lkn.css");

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --cl-color-primary: rgb(82 88 228);
  --cl-color-overlay-primary: rgb(82 88 228 / 50%);
  --cl-color-overlay-primary-hover: rgb(82 88 228 / 70%);
  --cl-color-overlay-dark: rgb(0 0 0 / 50%);
  --cl-color-overlay-darkest: rgb(0 0 0 / 80%);
  --cl-background-color: #e6e6e6;
  --cl-background-color-light: #F8F8F8;
  --cl-font-weight-overlay: 700;
  --cl-font-weight-title: 600;
  --cl-gap-large: 16px;
  --cl-gap: 10px;
  --cl-margin: 16px;
  --cl-padding: 16px;
  --cl-border-color: var(--cl-color-primary);
  --cl-border-color-default: #d5d5d5;
  --cl-border-radius: 12px;
  --cl-border-width: 1px;
  --cl-border: var(--cl-border-width) solid var(--cl-border-color-default);
  --cl-border-highlight: var(--cl-color-primary) solid 2px;
}

button {
  cursor: pointer !important;
}

li[role=menuitem] {
  cursor: pointer;
}
